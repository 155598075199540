<template>
  <div>
    <DataTable :value="data" v-model:filters="filtre" :globalFilterFields="FilterFilelds" responsiveLayout="scroll"
      :scrollable="true" class="p-datatable-sm" selectionMode="single" :paginator="true" :rows="10"
      :rowsPerPageOptions="[10, 20, 50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      dataKey="ID" resizableColumns columnResizeMode="fit" ref="dataTable" showGridlines stripedRows sortMode="multiple"
      removableSort>
      <!--columnResizeMode="expand" -->
      <template #empty> Veri Bulunamadı. </template>
      <template #loading> Yükleniyor. Lüften Bekleyin. </template>
      <template #header>
        <div class="p-grid">
          <div class="p-text-left p-col-6">
            <Button icon="pi pi-external-link" label="Export" class="p-button-raised p-button-rounded p-button-sm"
              @click="exportCSV($event)" />
          </div>
          <div class="p-text-right p-col-6">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filtre['global'].value" placeholder="Ara" />
            </span>
          </div>
        </div>
      </template>
      <Column sortable v-for="col of columns" :field="col.field" :header="col.header" :key="col.RowId">
      </Column>
      <Column v-if="DurumGoster" sortable header="Durum" field="Durum" :filterMenuStyle="{ width: '12rem' }"
        style="min-width: 10rem">
        <template #body="{ data }">
          <Tag :value="data.Durum" :severity="getSeverity(data.Durum)" />
        </template>
      </Column>
      <Column :exportable="false" style="max-width: 4rem">
        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-success" @click="edit(slotProps)" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  props: {
    columns: Array,
    data: Array,
  },
  data() {
    return {
      DurumGoster: true,
      filtre: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      FilterFilelds: [],
    };
  },
  methods: {
    exportCSV() {
      this.$refs.dataTable.exportCSV();
    },
    edit(EditRowData) {
      this.$emit("set-child-data", EditRowData);
    },
    getSeverity(status) {
      if (typeof(status) == "undefined") {
        this.DurumGoster = false;
        return "";
      }
      else {

        switch (status) {
          case "Yetkili":
            return "success";
          case "Yetkisiz":
            return "info";
          case null:
            return null;
          case "":
            return "";
        }

      }
    }
  },
  mounted() {
    for (let index = 0; index < this.columns.length; index++) {
      this.FilterFilelds.push(this.columns[index].field);
    }
    window.$('.p-datatable-thead').css("z-index", "0");
  },
  created() { },
};
</script>
