<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Roller</template>
      <template #content>
        <div class="top-button-area">
          <Button
            icon="pi pi-plus "
            class="p-button-sm p-mr-2 p-mb-2 yeni"
            label="Yeni"
            @click="_RolModal()"
          />
        </div>
        <DataTable
          v-if="DataTable_render"
          :columns="columns"
          :data="RolListesi"
          v-on:set-child-data="getDataChild"
        />
      </template>
    </Card>

    <Dialog
      v-model:visible="RolModal"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <template #header>
        <h4>Yeni Rol</h4>
      </template>
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-6">
          <span for="spanRolAd">Rol Adı</span>
          <InputText
            type="text"
            v-model="v$.RolAd.$model"
            aria-labelledby="spanRolAd"
            :class="{ 'p-invalid': v$.RolAd.$invalid && submitted }"
          />
          <small
            v-if="
              (v$.RolAd.$invalid && submitted) || v$.RolAd.$pending.$response
            "
            class="p-error"
            >{{ v$.RolAd.required.$message.replace("Value", "Name") }}</small
          >
        </div>
        <div class="p-field p-col-6">
          <span for="spanRolTanim">Rol Tanım</span>
          <InputText
            type="text"
            v-model="v$.RolAciklama.$model"
            aria-labelledby="spanRolTanim"
            :class="{ 'p-invalid': v$.RolAciklama.$invalid && submitted }"
          />
          <small
            v-if="
              (v$.RolAciklama.$invalid && submitted) ||
              v$.RolAciklama.$pending.$response
            "
            class="p-error"
            >{{
              v$.RolAciklama.required.$message.replace("Value", "Name")
            }}</small
          >
        </div>
      </div>

      <template #footer>
        <Button
          label="Tamam"
          icon="pi pi-check"
          class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)"
          autofocus
        />
        <Button
          label="Kapat"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          @click="this._RolModal()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataTable from "../../DataTable/DataTable.vue";
import GlobalServis from "../../../../services/GlobalServis";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      DataTable_render: true,
      RolListesi: [],
      columns: [
        { field: "RolAd", header: "Rol Adı" },
        { field: "RolAciklama", header: "Rol Tanım" },
      ],
      RolModal: false,
      RolModelDurum: null,

      RolAd: null,
      RolAciklama: null,
      Rol_rowid: null,
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.RolModal = !this.RolModal;
      this.RolModelDurum = false;
      this.Rol_rowid = getdata_child.data.Rol_rowid;
      let data = {
        Rol_rowid: getdata_child.data.Rol_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetRolInfo", data).then((res) => {
        if (res.status == 200) {
          this.v$.RolAd.$model = res.data[0].RolAd;
          this.v$.RolAciklama.$model = res.data[0].Aciklama;
          this.RolKod = res.data[0].Rol_rowid;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _RolModal() {
      this.RolModelDurum = true;
      this.RolModal = !this.RolModal;
      if (!this.RolModal) {
        // this.resetForm();
      }
    },
    handleClick(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          Rol_rowid: this.Rol_rowid,
          RolAd: this.RolAd,
          RolAciklama: this.RolAciklama,
        };
        if (this.RolModelDurum) {
          // Yeni Kullanıcı
          GlobalServis.GlobalServis("POST", "SetRol", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Rol oluşturulmuştur",
                life: 3000,
              });
              this.Refresh();
              this._RolModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        } else {
          // Edit Rol
          GlobalServis.GlobalServis("POST", "UpdateRol", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Rol düzenlemiştir",
                life: 3000,
              });
              this.Refresh();
              this._RolModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    InitServis() {
      GlobalServis.GlobalServis("GET", "GetRolTablosu", "").then((res) => {
        if (res.status == 200) {
          this.RolListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable,
  },
  validations() {
    return {
      RolAd: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      RolAciklama: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>../../../../services/GlobalServis
